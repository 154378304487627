@use "sass:map";

/**
 * Converts a SASS map of css property names and values into CSS output.
 * Properties named `description` will have their value inserted as comments.
 * 
 * Nested maps will be processed recursively.
 * 
 * @param  {map} $map the map of properties to output
 */
 @mixin apply-properties-map($map){
	@if type-of($map) == map {
		@each $prop, $value in $map {
			@if type-of($value) != map {
				@if inspect($prop) == 'description' {
					/* #{inspect($value)} */
				}
				@else {
					#{inspect($prop)}: #{inspect($value)};
				}
			}
			@else {
				@include apply-properties-map($value);
			}
		}
	}
}

@function fc-merge-maps($default-map, $child-maps...){
	$result-map: $default-map;

	@each $child-map in $child-maps {
		$result-map: map.deep-merge($result-map, $child-map);
    }

	@return $result-map;
}
@function fc-find-maps($sourceMap, $keys...){
    $map: $sourceMap;
	@if ($sourceMap) {
		@each $key in $keys {
			@if map-has-key($map, $key) {
				$map: map.get($map, $key);
			}
		}
	}

    @return $map;
}


@mixin fc-apply-properties($sourceMap, $keys...){
    $map: $sourceMap;
	@if ($sourceMap) {
		@each $key in $keys {
			@if map-has-key($map, $key) {
				$map: map.get($map, $key);
			}
		}
	}

	@if ($map) {
		@include apply-properties-map($map);
	}
}


@mixin fc-apply-responsive-properties($sourceMap, $keys...){
	$map-sm: $sourceMap;
    $map-md: $sourceMap;
    $map-lg: $sourceMap;


	@if ($sourceMap) {
		@each $key in $keys {
			@if map-has-key($map-sm, $key) {
				$map-sm: map.get($map-sm, $key, sm);
				$map-md: map.get($map-md, $key, md);
				$map-lg: map.get($map-lg, $key, lg);
			} @else {
				$map: null
			}
		}
	}

    @if ($map-sm) {
		@include apply-properties-map($map-sm);
	}

    @include media-breakpoint-up(md) {    
    	@include apply-properties-map($map-md);
    }

    @include media-breakpoint-up(lg) {    
        @include apply-properties-map($map-lg);
    }
}

