
$fc-table-base: (
    standardTable: (
        container: (
            background: color('light'),
            border-radius: 5px
        ),
        promoted: (
            container: (
                border-top-left-radius: 0
            )
        ), 
        advertised: (
            container: (
                border-top-left-radius: 0
            )
        )
    ),
    compactTable: (
        base: (
            background: color('light')
        )
    )
);


$fc-default-table: (
    columns-layout: (
        sm: (
            gap: 0,
            border-right: border(),
            border-top: border(),
            border-bottom: border(),
        )
    )
);

$fc-column-container: (
    flat: (
        sm: (
            gap: 0
        ),
    )
);



$fc-column: (
    shade-1: (
        background-color: color('body'),
        border-left: border()
    ), 
    shade-2: (
        background-color: color('body'),
        border-left: border()
    ), 
    shade-3: (
        background-color:  color('body'),
        border-left: border()
    ), 
    shade-4: (
        background-color:  color('body'),
        border-left: border()
    ), 
    shade-5: (
        background-color: color('body'),
        border-left: border()
    ), 
    stack-1: (
        background-color:  color('body'),
        border-left: border()
    ), 
    stack-2: (
        background-color:  color('body'),
        border-left: border()
    ), 
);

.test {
    border-top-left-radius: 0;
}