/**
 * Converts a SASS map of css property names and values into CSS output.
 * Properties named `description` will have their value inserted as comments.
 * 
 * Nested maps will be processed recursively.
 * 
 * @param  {map} $map the map of properties to output
 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
.test {
  border-top-left-radius: 0;
}